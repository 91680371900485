<template>

  <div>
    <v-progress-circular v-if="espera"
                        indeterminate
                        color="primary"                        
                        ></v-progress-circular>
    <v-card v-if="imageInfos.length > 0" class="mx-auto">
      <v-list>
        <v-subheader>{{$gettext('List of uploaded images')}}</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item v-for="(image, index) in imageInfos" :key="index">
            
            <v-img
          :src="image.url"
          class="my-3"
          contain
          height="100"
        />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="8">
        <v-file-input
        v-model="filename"
          show-size
          :label="$gettext('Selec a picture')"
          accept="image/*"
          @change="selectImage"
        ></v-file-input>
      </v-col>
      <v-col cols="4" class="pl-2">
        <v-btn color="success" dark small @click="upload">
          {{ $gettext('Upload') }}
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="progress">
      <div>
        <v-progress-linear
          v-model="progress"
          color="light-blue"
          height="25"
          reactive
        >
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </div>
    </div>
    <div v-if="previewImage">
      <div>
        <img class="preview my-3" :src="previewImage" alt="" />
      </div>
    </div>
    <v-alert v-if="message" border="left" color="blue-grey" dark>
      {{ message }}
    </v-alert>
    
  </div>
</template>

<script>
import UploadService from "../services/UploadFilesService";


export default {
  props:[
    'idRegistro'
  ],
  name: "upload-image",
  data() {
    return {
      currentImage: undefined,
      previewImage: undefined,
      espera:false,
      progress: 0,
      message: "",

      imageInfos: [],
      filename: null,
    };
  },
  methods: {
    selectImage(image) {
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.progress = 0;
      this.message = "";
    },

    upload() {
      if (!this.currentImage) {
        this.message = this.$gettext('Please select an image!');
        return;
      }
      this.espera = true;
      this.progress = 0;
      UploadService.upload(this.currentImage, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      }, this.idRegistro)
        .then((response) => {
          this.message = response.data.message;
          this.progress = false;
          this.previewImage = false;
          this.filename= null;
          this.espera = false;
          return UploadService.getFiles(this.idRegistro);
          
          
        })
        .then((images) => {
          this.imageInfos = images.data;
        })
        .catch((err) => {
          this.progress = 0;
          this.message = this.$gettext('Cant upload image!')  + err;
          this.currentImage = undefined;
        });
    },
  
  },
  mounted() {
    console.log(this.idRegistro);
    setTimeout(() => {
      UploadService.getFiles(this.idRegistro).then(response => {
        this.imageInfos = response.data;      
      });
    }, 2000);
    
  },
};
</script>

<style scoped>
.preview {
  max-width: 200px;
}
</style>
